

// payment posting page - with AB-490 chanages 
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Payment } from "@/modules/payment/payment.model";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { searchPayment } from "@/api/payment.api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getOrganization } from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  items: [];
  selectedItems: Payment[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    patientPayers: [];
  };
}

export default defineComponent({
  name: "PaymentPosting",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const orderList = [
      {
        name: "Payer",
        key: "payer",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: false,
      },
      {
        name: "Payment Type",
        key: "paymentType",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: false,
      },
      {
        name: "A/R Date",
        key: "arDate",
        isFilter: true,
        formType: "isDate",
        keyword: null,
        IsAdvancedOrderBy: false,
      },
      {
        name: "Payment Date",
        key: "paymentDate",
        isFilter: true,
        formType: "isDate",
        keyword: null,
        IsAdvancedOrderBy: false,
      },
      {
        name: "Amount",
        key: "totalAmount",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: false,
      },
      {
        name: "Check/Remittance #",
        key: "paymentNumber",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: false,
      },
      {
        name: "Remaining to Post",
        key: "unallocatedBalance",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: false,
      },
    ];

    onMounted(async () => {
      organizationId.value = getOrganization();

      setCurrentPageTitle("Payments");
      await getAll();
    });

    async function cleanFilter() {
      data.selectedItems = [];
      data.keyword = "";
      data.orderBy = "status";
      data.isDecr = true;
      data.IsAdvancedOrderBy = false;
      orderList.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      data.selectedItems = [];
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let constList = orderList
        .filter((item) => {
          return !item.IsAdvancedOrderBy;
        })
        .map((item) => {
          return item.key;
        });

      constList.push("encounter.patient.firstName");

      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: constList,
          keyword: data.keyword,
        },
        ClaimId: getFilter("claimId"),
        PatientId: getFilter("encounter.patient.accountId"),
        LastName: getFilter("encounter.patient.lastName"),
        DOS: getFilter("dos"),
        Facility: getFilter("encounter.facility.name"),
        ResponsibleParty: getFilter("payer.name"),
        TotalCharges: getFilter("totalCharges"),
        BalanceDue: getFilter("balanceDue"),
        Age: getFilter("age"),
        BillingIndicator: getFilter("billingIndicator"),
        ReviewDate: getFilter("reviewDate"),
        Status: getFilter("status"),
      };

      const res = await searchPayment(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    function getFilter(key) {
      const header = orderList.find((item) => {
        return item.key == key;
      });
      if (header && header.keyword) {
        return header.keyword;
      }
      return null;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    
    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      selectedItems: [],
      orderBy: "status",
      IsAdvancedOrderBy: false,
      isDecr: true,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      panel: {},
      dict: {
        patientPayers: [],
      },
    });

    return {
      organizationId,
      clearSearch,
      data,
      orderList,
      search,
      cleanFilter,
      getSortInfo,

      // selectItem,
      // selectPage,
      // paginationChanged,
      // changePage,
      // getSortInfo,
      selectFilter,
      // getStatusHeader,
      // sendAction,
      // newEncounter,
    };
  },
});
